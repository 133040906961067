import React from 'react'
import Nav from "../components/Nav.js"
import Footer from '../components/Footer.js'
import TeamMember from '../components/teamMember.js'
import { team } from '../data.js'

const Team = () => {
    { window.scrollTo(0, 0) }

    return (
        <div>
            <Nav />
            <div className="isolate bg-white px-6 lg:px-8">

                <div className="mx-auto max-w-5xl text-center">
                    <div className='flex items-center pt-40'>
                        <hr class="h-0.5 mt-2 rounded-2xl bg-slate-gray flex-1 mr-12"></hr>
                        <h2 className="text-3xl text-blue-600 font-bold font-palanquin tracking-tight sm:text-4xl text-center">Meet the Team</h2>
                        <hr class="h-0.5 mt-2 rounded-2xl bg-slate-gray flex-1 ml-12"></hr>
                    </div>
                    <div className='max-w-lg mx-auto mt-3'>
                        <p className="text-slate-gray mt-1 font-palanquin text-md text-center">
                            Meet the team dedicated to providing seemless web and merchandise solutions.
                        </p>
                    </div>

                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 pt-10">

                        {
                            team.map((member) => (
                                < TeamMember name={member.name} role={member.role} image={member.image} />
                            ))
                        }
                        
                    </div>



                </div>
            </div>






            <br></br>
            <Footer />
        </div>
    )
}

export default Team;













// <section class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
//
//     <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
//         <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
//             <div class="mb-8">
//                 <img class="object-center object-cover rounded-full h-36 w-36" src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80" alt="photo">
//             </div>
//             <div class="text-center">
//                 <p class="text-xl text-gray-700 font-bold mb-2">Dany Bailey</p>
//                 <p class="text-base text-gray-400 font-normal">Software Engineer</p>
//             </div>
//         </div>
//         <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
//             <div class="mb-8">
//                 <img class="object-center object-cover rounded-full h-36 w-36" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80" alt="photo">
//             </div>
//             <div class="text-center">
//                 <p class="text-xl text-gray-700 font-bold mb-2">Lucy Carter</p>
//                 <p class="text-base text-gray-400 font-normal">Graphic Designer</p>
//             </div>
//         </div>
//         <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
//             <div class="mb-8">
//                 <img class="object-center object-cover rounded-full h-36 w-36" src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1176&q=80" alt="photo">
//             </div>
//             <div class="text-center">
//                 <p class="text-xl text-gray-700 font-bold mb-2">Jade Bradley</p>
//                 <p class="text-base text-gray-400 font-normal">Dev Ops</p>
//             </div>
//         </div>
//         <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
//             <div class="mb-8">
//                 <img class="object-center object-cover rounded-full h-36 w-36" src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80" alt="photo">
//             </div>
//             <div class="text-center">
//                 <p class="text-xl text-gray-700 font-bold mb-2">Dany Bailey</p>
//                 <p class="text-base text-gray-400 font-normal">Software Engineer</p>
//             </div>
//         </div>
//         <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
//             <div class="mb-8">
//                 <img class="object-center object-cover rounded-full h-36 w-36" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80" alt="photo">
//             </div>
//             <div class="text-center">
//                 <p class="text-xl text-gray-700 font-bold mb-2">Lucy Carter</p>
//                 <p class="text-base text-gray-400 font-normal">Graphic Designer</p>
//             </div>
//         </div>
//         <div class="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
//             <div class="mb-8">
//                 <img class="object-center object-cover rounded-full h-36 w-36" src="https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1176&q=80" alt="photo">
//             </div>
//             <div class="text-center">
//                 <p class="text-xl text-gray-700 font-bold mb-2">Jade Bradley</p>
//                 <p class="text-base text-gray-400 font-normal">Dev Ops</p>
//             </div>
//         </div>
//     </div>
