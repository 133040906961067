import Nav from "../components/Nav"
import Footer from "../components/Footer";
import { products } from "../data";
import { Link } from "react-router-dom";
import { ShoppingCart } from "../components/ShoppingCart";
import { ShopContext } from '../context/ShopContext'
import { useContext } from "react";
import { useState } from "react";

import merchComingSoon from "../images/merchComingSoon.jpg"

export default function Merch() {
    { window.scrollTo(0, 0) }

    const [cartOpen, setCartOpen] = useState(false);
    const { cartItems } = useContext(ShopContext);

    let productCount = 0;
    for (const item in cartItems) {
        for (let i = 0; i < cartItems[item].length; i++) {
            if (cartItems[item][i] > 0) {
                productCount += cartItems[item][i];
            }
        }
    }
    return (
        <div>
            <Nav />
            <ShoppingCart cartOpen={cartOpen} setCartOpen={setCartOpen} />
            <div className='relative px-4 sm:px-12'>
                <div className='xl:padding-l wide:padding-r sm:pb-12 pb-6'>
                    <div className='max-w-5xl mx-auto'>
                        <div className='pt-40 flex justify-center items-center'>
                            <hr class="h-0.5 mt-2 rounded-2xl bg-slate-gray flex-1 mr-12"></hr>

                            <h1 className='text-blue-600 font-semibold text-4xl font-palanquin inline-block text-center'>Official Merch Store</h1>
                            <hr class="h-0.5 mt-2 rounded-2xl bg-slate-gray flex-1 ml-12"></hr>
                        </div>
                        <div className='max-w-sm mx-auto mt-3'>
                            <p className='text-slate-gray mt-1 font-palanquin text-md text-center'>
                                Check out some of my merch with the latest fashion trends! This is a great way to support my small buisness and spread the sense of commmunity!
                            </p>
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="mt-10 flex items-center font-semibold justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-blue-600 hover:border-2 hover:border-blue-600 transition-all"
                                onClick={() => setCartOpen(true)}>
                                Cart ( {productCount} items )
                            </button>
                        </div>
                        {/* <p className="mt-3 text-red-500 text-xl font-bold underline -my-3">All merch is out of stock right now. Please check back for updates.</p> */}
                        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8 mt-11">
                            {products.map((product) => (
                                <Link to={`/merch/${product.id}`}>

                                    <a key={product.id} href={product.href} className="group">
                                        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-blue-200 xl:aspect-h-8 xl:aspect-w-7">
                                            <img
                                                src={product.imageSrc}
                                                alt={product.imageAlt}
                                                className="h-full w-full object-cover object-center group-hover:opacity-75"
                                            />
                                        </div>
                                        <div className="flex justify-between">
                                            <h3 className="mt-4 text-md font-semibold text-gray-700">{product.data.name}</h3>
                                            <div className="flex gap-3">
                                                <p className="mt-4 line-through text-md font-medium font-semibold text-black">${product.data.price}</p>
                                                <p className="mt-4 text-md font-medium font-semibold text-blue-600">${product.data.sale_price}</p>

                                            </div>
                                        </div>
                                    </a>
                                </Link>
                            ))}

                            <div>
                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-blue-200 xl:aspect-h-8 xl:aspect-w-7">
                                    <img
                                        src={merchComingSoon}
                                        className="h-full w-full object-cover object-center"
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <h3 className="mt-4 text-md font-semibold text-gray-700">More Designs Coming Soon!</h3>

                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
            {/* <ProductOverview /> */}
            <Footer />
        </div>
    )
}