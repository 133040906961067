const TeamMember = ({name, role, image}) => (
    <div class="bg-blue-50 rounded-lg p-12 flex flex-col justify-center items-center">
        <div class="mb-8">
            <img class="object-center object-cover rounded-full h-36 w-36" src={image} alt="photo" />
        </div>
        <div class="text-center">
            <p class="text-xl text-black font-bold mb-2">{name}</p>
            <p class="text-base text-gray-400 font-normal">{role}</p>
        </div>
    </div>
)

export default TeamMember;