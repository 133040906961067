import { Fragment, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ShopContext } from '../context/ShopContext'
import { products } from '../data'


const ShoppingCart = ({ cartOpen, setCartOpen }) => {

  const { cartItems, removeFromCart, getTotalCartAmount } = useContext(ShopContext);
  const totalAmount = getTotalCartAmount();

  const sizeLetters = ["Small", "Medium", "Large", "Extra Large"]



  const checkout = async() => {
    await fetch(process.env.NODE_ENV === "production" ? "https://avibandi-com-server.vercel.app/checkout" : "http://localhost:4000/checkout",
      
      
       {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        items: cartItems
      })
    }).then((response) => {
      return response.json();
    }).then(response => {
      if (response.url) {
        window.location.assign(response.url);
      }
    })
  }


  return (

    <Transition.Root show={cartOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setCartOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-md text-blue-600 font-bold">Shopping cart</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setCartOpen(false)}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul role="list" className="-my-6 divide-y divide-gray-200">
                            {/* BRO THIS CHATPGT CLUTCH IS WILD. IDK HOW IT CAME UP WITH THIS WIRD SIZEINDEX RENDERING. */}
                            {products.map((product) => {
                              return [0, 1, 2, 3].map((sizeIndex) => {
                                if (cartItems[product.id][sizeIndex] > 0) {
                                  console.log("product", product.id, "sizeIndex", sizeIndex, "IS GREATER THAN 0");
                                  return (
                                    <li key={product.id + "-" + sizeIndex} className="flex py-6">
                                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                        <img src={product.imageSrc} className="h-full w-full object-cover object-center" />
                                      </div>

                                      <div className="ml-4 flex flex-1 flex-col">
                                        <div>
                                          <div className="flex justify-between ">
                                            <div>
                                              <h6 className='text-base font-medium text-gray-900'>{product.data.name}</h6>
                                              <h6 className='text-sm pt-1 text-gray-500'>{sizeLetters[sizeIndex]}</h6>
                                            </div>
                                            <p className="ml-4">${product.data.sale_price}</p>
                                          </div>
                                        </div>
                                        <div className="flex flex-1 items-end justify-between text-sm">
                                          <p className="text-gray-500">Qty {cartItems[product.id][sizeIndex]}</p>

                                          <div className="flex">
                                            <div>
                                              <button
                                                type="button"
                                                onClick={() => removeFromCart(product.id, sizeIndex)}
                                                className="font-medium text-blue-600 hover:text-gray-900 font-semibold transition-all"
                                              >
                                                Remove one
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                } else {
                                  return null; // If the condition is not met, return null or an empty fragment
                                }
                              });
                            })}

                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Subtotal</p>
                        <p>${totalAmount}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                      <div className="mt-6">
                        <button
                          onClick={checkout}
                          className="flex items-center justify-center rounded-md border-2 border-blue-600 border-blue-600 bg-blue-600 px-6 py-3 transition-all text-base font-medium text-white font-md font-semibold shadow-sm hover:bg-white hover:text-blue-600">
                          Checkout
                        </button>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or{' '}
                          <button
                            type="button"
                            className="font-medium text-blue-600 font-semibold hover:text-gray-900 transition-all"
                            onClick={() => setCartOpen(false)}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export { ShoppingCart };
