import Nav from './Nav'
import { useState } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { RadioGroup, } from '@headlessui/react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ShoppingCart } from './ShoppingCart'
import Footer from './Footer'
import { ShopContext } from '../context/ShopContext'
import { useContext } from 'react'
import { Link } from 'react-router-dom'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProductOverview({ product }) {
    { window.scrollTo(0, 0) }

    const [selectedSize, setSelectedSize] = useState(product.sizes[2])
    const [cartOpen, setCartOpen] = useState(false);

    console.log(selectedSize.id, "selected size")
    const { addToCart, cartItems, removeFromCart } = useContext(ShopContext);
    console.log("Product ID is", selectedSize.id)
    return (

        <div className="bg-white">

            <ShoppingCart cartOpen={cartOpen} setCartOpen={setCartOpen} />

            <div className=''>
                <Nav />
            </div>

            <div className="pt-[120px]">
                {/* Breadcrumbs */}

                <nav className='max-w-5xl mx-auto'>
                    <div className='flex justify-between'>
                        <div>
                            <ol role="list" className="mx-auto flex items-center space-x-2 px-4 sm:px-6 lg:px-8">
                                {product.breadcrumbs.map((breadcrumb) => (
                                    <li key={breadcrumb.id}>
                                        <div className="flex items-center">
                                            <Link to={breadcrumb.href}>
                                                <h2 className="mr-2 text-sm font-medium text-gray-900 hover:text-blue-600 underline">
                                                    {breadcrumb.name}
                                                </h2>
                                            </Link>
                                            <svg
                                                width={16}
                                                height={20}
                                                viewBox="0 0 16 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                                className="h-5 w-4 text-gray-300"
                                            >
                                                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                            </svg>
                                        </div>
                                    </li>
                                ))}
                                <li className="text-sm">
                                    <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                                        {product.name}
                                    </a>
                                </li>
                            </ol>
                        </div>
                        <Link to="/merch">
                            <h1 className='class="mr-2 text-sm font-medium text-gray-900 hover:underline hover:text-blue-600 px-4 sm:px-6 lg:px-8'>Back</h1>
                        </Link>
                    </div>
                </nav>


                {/* Image gallery */}
                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-5xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
                    <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block border-2 border-blue-600">
                        <img
                            src={product.images[0].src}
                            alt={product.images[0].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block border-2 border-blue-600">
                        <img
                            src={product.images[1].src}
                            alt={product.images[1].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg sm:border-2 border-blue-600 border-0">
                        <img
                            src={product.images[2].src}
                            alt={product.images[2].alt}
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                </div>

                {/* Product info */}
                <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-5xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
                    <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-blue-600 sm:text-3xl">{product.name}

                            {
                                product.outOfStock === true ? <span className="text-red-600 text-2xl sm:text-3xl"> - Out of Stock</span> : null
                            }
                        </h1>
                    </div>

                    {/* Options */}
                    <div className="mt-4 lg:row-span-3 lg:mt-0">


                        <h2 className="sr-only">Product information</h2>
                        <div className='flex gap-3'>
                            <p className="text-3xl tracking-tight text-gray-900 line-through">${product.price}</p>
                            <p className="text-3xl tracking-tight text-blue-600 font-semibold">${product.sale_price}</p>

                        </div>

                        <form className="mt-10">
                            {/* Colors
              <div>
                <h3 className="text-sm font-medium text-gray-900">Color</h3>

                <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-4">
                  <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                  <div className="flex items-center space-x-3">
                    {product.colors.map((color) => (
                      <RadioGroup.Option
                        key={color.name}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedClass,
                            active && checked ? 'ring ring-offset-1' : '',
                            !active && checked ? 'ring-2' : '',
                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                          )
                        }
                      >
                        <RadioGroup.Label as="span" className="sr-only">
                          {color.name}
                        </RadioGroup.Label>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            color.class,
                            'h-8 w-8 rounded-full border border-black border-opacity-10'
                          )}
                        />
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div> */}

                            {/* Sizes */}
                            <div className="mt-10">
                                <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
                                    <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>
                                    <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                                        {product.sizes.map((size) => (
                                            <RadioGroup.Option
                                                key={size.name}
                                                value={size}
                                                disabled={!size.inStock}
                                                className={({ active }) =>
                                                    classNames(
                                                        size.inStock
                                                            ? 'cursor-pointer bg-white text-gray-900 shadow-sm'
                                                            : 'cursor-not-allowed bg-gray-50 text-gray-200',
                                                        active ? 'ring-2 ring-blue-600' : '',
                                                        'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                                                    )
                                                }
                                            >
                                                {({ active, checked }) => (
                                                    <>
                                                        <RadioGroup.Label as="span">{size.name}</RadioGroup.Label>
                                                        {size.inStock ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? 'border' : 'border-2',
                                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                                    'pointer-events-none absolute -inset-px rounded-md'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            <span
                                                                aria-hidden="true"
                                                                className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                                            >
                                                                <svg
                                                                    className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                                                    viewBox="0 0 100 100"
                                                                    preserveAspectRatio="none"
                                                                    stroke="currentColor"
                                                                >
                                                                    <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </RadioGroup.Option>
                                        ))}
                                    </div>
                                </RadioGroup>
                            </div>
                            <button
                                type="button"
                                className="mt-10 flex w-full items-center font-semibold justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-blue-600 hover:border-2 hover:border-blue-600 transition-all"
                                onClick={() => { addToCart(product.id, selectedSize.id); setCartOpen(true) }}
                                disabled={product.outOfStock}

                            >
                                Add to Cart
                            </button>
                        </form>

                        <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                            <p>
                                or{' '}
                                <button
                                    type="button"
                                    className="font-medium text-blue-600 font-semibold hover:text-gray-900 transition-all"
                                    onClick={() => setCartOpen(true)}
                                >
                                    Go to Cart
                                    <span aria-hidden="true"> &rarr;</span>
                                </button>
                            </p>
                        </div>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
                        {/* Description and details */}
                        <div>
                            <h3 className="sr-only">Description</h3>

                            <div className="space-y-6">
                                <p className="text-base text-gray-900">{product.description}</p>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h3 className="text-sm font-medium text-gray-900">Highlights</h3>

                            <div className="mt-4">
                                <ul role="list" className="list-disc space-y-2 pl-4 text-sm">
                                    {product.highlights.map((highlight) => (
                                        <li key={highlight} className="text-gray-400">
                                            <span className="text-gray-600">{highlight}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
