import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";

import Logo from "../images/logo.png";

const Nav = () => {
  const navLinks = [
    { href: "/home", label: "Home" },
    { href: "/portfolio", label: "Portfolio" },
    { href: "/services", label: "Services" },
    { href: "/team", label: "Team" },
    { href: "/merch", label: "Merch" },
    { href: "/contact", label: "Contact" },
  ];
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [shadow, setShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // bg-white shadow-lg fixed top-0 left-0 w-full z-50

    // orignal
    // padding-x py-8 absolute z-10 w-full

    <header
      className={`bg-orange-50 fixed top-0 left-0 w-full z-40 py-3 transition-shadow duration-300 ${
        shadow ? "shadow-lg" : ""
      }`}
    >
      <nav className="flex justify-between px-4 max-container">
        <a href="/">
          <img src={Logo} alt="Logo" className="w-16 rounded-lg" />
        </a>
        <ul className="flex-1 flex justify-end items-center gap-12 max-lg:hidden">
          {navLinks.map((item) => (
            <Link to={item.href}>
              <button>
                {item.label === "Contact" ? (
                  <h1 className="text-white text-2xl font-semibold font-palanquin border-2 border-blue-600 bg-blue-600 rounded-lg px-4 py-2 hover:text-blue-600 hover:bg-transparent transition-colors">
                    {item.label}
                  </h1>
                ) : (
                  <h1 className="font-montserrat leading-normal text-2xl text-slate-gray font-semibold hover:text-blue-600 hover:underline transition-all">
                    {item.label}
                  </h1>
                )}
              </button>
            </Link>
          ))}
        </ul>

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>

            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="#ef4444"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </nav>

      <div className="lg:flex lg:gap-x-12 justify-right">
        {navLinks.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className="text-2xl font-semibold leading-6 text-black"
          >
            {item.name}
          </a>
        ))}
      </div>

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-100 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>

              <img className="w-8 w-auto rounded-2xl" src={Logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
            </button>
          </div>

          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navLinks.map((item) => (
                  <a
                    key={item.label}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-blue-600 hover:bg-gray-300 transition-all"
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Nav;
