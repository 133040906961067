

import './index.css';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Merch from './pages/Merch';
import Team from './pages/Team';



import { products } from './data';
import ProductOverview from './components/ProductOverview';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShopContextProvider } from './context/ShopContext';
import Success from './pages/Success';

function App() {
  return (
    <div className="App">
      <ShopContextProvider>

        <BrowserRouter>

          <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/portfolio" element={<Projects />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/team" element={<Team />} />

            <Route path="/merch" element={<Merch />} />

            <Route path="/success" element={<Success />} />

            {
              // Route for each product
              products.map((product) => (
                <Route path={`/merch/${product.id}`} element={<ProductOverview product={product.data} />} />
              ))
            }


          </Routes>

        </BrowserRouter>
      </ShopContextProvider>

    </div>
  );
}

export default App;



