export default function Example() {
  return (
    <>
      <main className="grid min-h-full min-w-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-blue-600">We value your support!</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Thank you for your purchase!</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">You should hear from us in the next 24 hours!</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/merch"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Back to Merch Store
            </a>
            <a href="/home" className="text-sm font-semibold text-gray-900">
              Back Home <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  )
}
