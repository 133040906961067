import { createContext, useState } from "react";
import { products } from "../data";
export const ShopContext = createContext(null);

const getDefaultCart = () => {
  let cart = {};
  for (let i = 1; i < products.length + 1; i++) {
    cart[i] = [0, 0, 0, 0];
  }
  return cart;
};

export const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart());

  const getTotalCartAmount = () => {
    let totalAmount = 0;

    for (const item in cartItems) {
      for (let i = 0; i < cartItems[item].length; i++) {
        if (cartItems[item][i] > 0) {
          let itemInfo = products.find((product) => product.id === Number(item));
          totalAmount += Number(itemInfo.data.sale_price) * Number(cartItems[item][i]);
        }
      }
    }
    // am using 500 as testing. need to figure out algorithim for total price
    // return totalAmount;
    return totalAmount;
  }

  const addToCart = (itemId, index) => {

    setCartItems((prev) => {
      // Create a copy of the previous state
      const updatedCartItems = { ...prev };
      // Increment the value at the specified index of the item array
      updatedCartItems[itemId][index] += 1;

      


      return updatedCartItems;
    });
  };

  const removeFromCart = (itemId, index) => {
    setCartItems((prev) => {
      // Create a copy of the previous state
      const updatedCartItems = { ...prev };
      // Increment the value at the specified index of the item array
      updatedCartItems[itemId][index] -= 1;
      return updatedCartItems;
    });
  };


  const contextValue = {
    cartItems,
    addToCart,
    removeFromCart,
    getTotalCartAmount,
  };

  console.log("These are the cart items")
  console.log(cartItems)
  return (
    
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};